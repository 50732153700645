// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-contacto-personalizado-jsx": () => import("./../../../src/pages/contacto-personalizado.jsx" /* webpackChunkName: "component---src-pages-contacto-personalizado-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-productos-jsx": () => import("./../../../src/pages/productos.jsx" /* webpackChunkName: "component---src-pages-productos-jsx" */),
  "component---src-pages-productos-personalizados-jsx": () => import("./../../../src/pages/productos-personalizados.jsx" /* webpackChunkName: "component---src-pages-productos-personalizados-jsx" */),
  "component---src-pages-proyectos-jsx": () => import("./../../../src/pages/proyectos.jsx" /* webpackChunkName: "component---src-pages-proyectos-jsx" */),
  "component---src-pages-resultados-busqueda-jsx": () => import("./../../../src/pages/resultados-busqueda.jsx" /* webpackChunkName: "component---src-pages-resultados-busqueda-jsx" */),
  "component---src-pages-soporte-jsx": () => import("./../../../src/pages/soporte.jsx" /* webpackChunkName: "component---src-pages-soporte-jsx" */),
  "component---src-templates-category-detail-jsx": () => import("./../../../src/templates/categoryDetail.jsx" /* webpackChunkName: "component---src-templates-category-detail-jsx" */),
  "component---src-templates-custom-product-jsx": () => import("./../../../src/templates/customProduct.jsx" /* webpackChunkName: "component---src-templates-custom-product-jsx" */),
  "component---src-templates-product-detail-jsx": () => import("./../../../src/templates/productDetail.jsx" /* webpackChunkName: "component---src-templates-product-detail-jsx" */),
  "component---src-templates-project-detail-jsx": () => import("./../../../src/templates/projectDetail.jsx" /* webpackChunkName: "component---src-templates-project-detail-jsx" */)
}

